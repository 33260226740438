.yj-contact{ z-index: $zIndexModal; position: fixed; right: 40px; bottom: 200px; width: 80px; height: 240px; background-color: rgba(255,255,255,.9); box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05); border-radius: 100px; display: flex; flex-direction: column; justify-content: center;
  .contact{ cursor: pointer; text-decoration: none; position: relative;
    & +.contact{ margin-top: 35px; }
    &:hover{
      .modal{ display: flex; }
    }
    &:nth-child(2){
      .modal{ width: 168px; height: 168px; left: -180px; top: -25px; }
    }
    .icon{ display: block; height: 44px; margin: 0 auto;}
    .title{ color: #303133; opacity: 0.8; font-size: 14px; margin-top: 10px; text-align: center; }
    .modal{ display: none; flex-direction: column; justify-content: center; text-align: center;  transition: all .3s; border-radius: 16px; cursor: default; position: absolute;  top: 0; left: -250px; width: 230px; padding: 25px 0; color: #606266; background: #FFFFFF; box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.05);
      &::before{ content: ''; width: 16px; height: 16px; background-color: #FFFFFF; position: absolute; right: -1px; top: 30%; transform: rotate(135deg) translateY(-50%); } 
      .tel{ color: #DAB265; font-weight: 600; font-size: 24px; }
      .date{ font-size: 14px; }
      .qr{ display: block; width: 90px; height: 90px; margin: 0 auto;}
      .sub{ font-size: 14px; color: #606266; margin-top: 5px;}
    }
  }
}
.yj-modal{ display: none; width: 320px; height: 320px; background-color: #fff; box-shadow: 10px 0 20px 0 rgba(0, 0, 0, .2); border-radius: 20px; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); z-index: $zIndexModal;
  .del{ display: block; height: 20px; position: absolute; right: 20px; top: 20px; cursor: pointer; }
  .modal{ height: 320px; display: flex; flex-direction: column; justify-content: center;
    .qr{ display: block; width: 50%; margin: 0 auto; }
    .sub{ display: flex; align-items: center; margin: 0 auto; margin-top: 20px;
      .icon{ display: block; height: 30px; margin-right: 10px; }
      .text{ }
    }
  }
}
@media screen and (max-width: 1296px){
  .yj-contact{ bottom: 25px; right: 10px;
    .contact{
      &:hover{
        .modal{ display: none; }
      }
      .modal{ display: none; }
    }
  }
}
@media screen and (max-width : 1023px){
  .yj-contact{ bottom: 25px; right: 10px; height: 160px; width: 60px;
    .contact{
      &+.contact{ margin-top: 10px; }
      .icon{ height: 30px; }
      .title{ font-size: 12px; }
      &:hover{
        .modal{ display: none; }
      }
      .modal{ display: none; }
    }
  }
}