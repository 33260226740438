.yj-nav{ z-index: $zIndexHeader; background-color: rgba(255,255,255, 0); position: fixed; top: 0; left: 0; right: 0;
  .nav{ height: 80px; padding: 30px 0; margin: 0 auto; display: flex; justify-content: space-between; align-items: center; 
    .nav-left{ display: flex; position: relative;
      .nav-icon{ margin-right: 40px; cursor: pointer; 
        img{ height: 40px; }
      }
      .nav-unfold{ display: block; height: 32px; position: absolute; top: 0; right: 0;}
      .nav-del{ display: none; height: 32px; position: absolute; top: 0; right: 0;}
      .nav-list{ display: flex; align-items: center; 
        .item{ position: relative; cursor: pointer; font-size: 16px; color: #fff; text-decoration: none; line-height: 40px; overflow: hidden;
          &+.item{ margin-left: 32px; }
          &::before{ content: ""; display: block;  width: 30px; height: 5px; background-color: #fff; border-radius: 7px; position: absolute; bottom: 0; left: -50%; transform: translateX(-50%); transition: all .3s;}
          &:hover{
            &::before{ left: 50%; }
          }
          &.active{
            &::before{ content: ""; display: block;  width: 30px; height: 5px; background-color: #fff; border-radius: 7px; position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); }
          }
          &.action{
            &::before{ background-color: #DAB265; }
          }
          &.action1{
            &::before{ background-color: #DAB265; }
          }
        }
      }
    }
    .nav-content{ text-decoration: none; padding: 9px 16px; text-align: center; background: rgba(255,255,255,.3); border-radius: 100px; cursor: pointer; transition: all .3s;
      &:hover{ background: #DAB265; }
      span{ transition: all .3s; opacity: 0.9; font-size: 16px; color: #FFFFFF; }
    }
  }
  .yj-navigation{ display: none; position: fixed; top: 60px; right: 0; bottom: 0; left: 0; background-color: #fff;
    .navigation{ padding: 30px; list-style: none; 
      &.active{ max-height: 306px; overflow-y: auto; }
      a{ display: block; font-size: 15px; padding: 15px 0; border-bottom: 1px solid rgba(0,0,0,0.1); width: 100%;  text-decoration: none; color: #303133;
        &.active{ color: #DAB265; }
        span{ color: #DAB265; }
      }
    }
  }
}
.yj-active{ background-color: #fff !important; box-shadow: 0 2px 12px 0 rgba(0,0,0,0.06); transition: all .3s;
  .nav{
    .nav-list{
      .item{ color: #303133 !important; }
    }
    .nav-content{ background: #DAB265 !important; 
      &:hover{ 
        span{color: #303133 !important;}
      }
    }
  }
}
@media screen and (max-width : 1296px){
  .yj-nav{
    .nav{ padding: 0 10px; height: 60px;
      .nav-left{ width: 100%; 
        .nav-icon{
          img{ height: 30px; }
        }
        .nav-unfold{ height: 18px; top: 4px; }
        .nav-del{ height: 24px; top: 4px; }
      }
    }
  }
}
//移动端
@media only screen and (max-width: 1023px){
  .yj-nav{
    .nav{ padding: 30px 0; max-width: calc(100% - 40px);
      .nav-left{
        .nav-list { display: none; }
      }
      .nav-content{ display: none; }
    }
  }
}
//ipad
@media screen and (min-width: 1024px) and (max-width : 1296px){
  .yj-nav{
    .nav{ max-width: calc(100% - 48px);
      .nav-left{
        .nav-list { display: none; }
      }
      .nav-content{ display: none; }
    }
  }
}
@media screen and (min-width : 1297px){
  .yj-nav{
    .nav{
      .nav-left{
        .nav-unfold{ display: none; }
        .nav-del{ display: none; }
      }
    }
    .yj-navigation{ display: none; }
  }
}
