.yj-footer{ position: relative; overflow: hidden;
  .footer-content{ background:#171717; padding: 67px 10px; 
    .yj-wrapper { max-width: 1200px; margin: 0 auto; display: flex; justify-content: center; align-items: center;
      .content-list{ display: flex; flex: 1; min-height: 0; 
        .content-item{ margin-right: 80px; 
          .item-title{ margin-bottom: 27px; color: #FFFFFF; font-size: 16px; font-weight: 600;}
          .item{ color: #909399; font-size: 14px; cursor: pointer;
            a{ text-decoration: none; color: #909399;
              &:hover{ color: #fff; }
            }
            & + .item{ margin-top: 18px; }
          }
        }
      }
      .content-scan{ display: flex; margin-left: 80px; 
        .scan-item{ transition: all .3s;
          & + .scan-item{ margin-left: 24px; }
          &:hover{ 
            .scan{ background-color: #fff;  transition: all .3s; 
              .i{ background: url('https://qiniu.yunjilink.com/d349a3372b683a19a54e0004ce150ad5.png') no-repeat; background-size: contain; }
              .a{ background: url('https://qiniu.yunjilink.com/7d777ce0c3d60ec2f342ba3f22e59481.png') no-repeat; background-size: contain; }
            }
          }
          .scan{ width: 100px; height: 100px; border: 1px solid #606266; display: block;
            span{ display: block; width: 30px; height: 30px; margin: 35% auto 10px; }
            .i{ background: url('https://qiniu.yunjilink.com/b8569499997663db3ed680bfec19b8ed.png') no-repeat; background-size: contain; }
            .a{ background: url('https://qiniu.yunjilink.com/fa1bc31d12e63a3117cb44468b74c268.png') no-repeat; background-size: contain; }
            img{ width: 100%; display: block; }
          }
          .name{ text-align: center; font-size: 14px; color: #909399; margin-top: 12px; }
        }
      }
    }
  }
  .footer-copyright{ padding: 25px 0 20px 0; background: #000000; 
    .yj-wrapper{ max-width: 1200px; margin: 0 auto; 
      .item{ font-size: 12px; color: #909399; text-align: center; 
        a{ color: #909399; text-decoration: none; cursor: pointer; 
          &:hover{ color: #fff; }
        }
      }
    } 
  }
}
@media screen and (max-width: 768px){
  .yj-footer{
    .footer-content{ padding: 10px 20px; padding-top: 30px;
      .yj-wrapper{ display: block;
        .content-list{ display: block;
          .content-item{ margin-right: 20px; margin-top: 10px;
            & + .content-item{ margin-top: 20px; }
            .item-title{ margin-bottom: 10px; }
            &:nth-child(1){ 
              .item{ display: inline-block; margin-top: 0; margin-right: 12px;}
            }
          }
        }
        .content-scan{ margin-left: 0; margin-top: 20px; flex-wrap: wrap; 
          .scan-item{ margin: 10px auto; 
            & + .scan-item{ margin: 10px auto;} 
          }
        }
      }
    }
    .footer-copyright{ padding: 10px 20px;}
  }
}
@media screen and (min-width: 769px) and (max-width: 1296px){
  .yj-footer{
    .footer-content{ padding: 10px 20px;
      .yj-wrapper{
        .content-list{ display: block;
          .content-item{ margin-right: 20px; margin-top: 10px;
            & + .content-item{ margin-top: 20px; }
            .item-title{ margin-bottom: 10px; }
            &:nth-child(1){ 
              .item{ display: inline-block; margin-top: 0; margin-right: 12px;}
            }
            &:nth-child(2){ 
              .item{ display: inline-block; }
            }
          }
        }
        .content-scan{ margin-left: 0; margin-top: 20px; flex: 1;
          .scan-item{ margin: 10px auto; 
            & + .scan-item{ margin: 10px auto;} 
          }
        }
      }
    }
    .footer-copyright{ padding: 10px 20px;}
  }
}