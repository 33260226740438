* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #ffffff;
  color: #2c302f;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 15px;
}

// 总页面
.yj-website-wrapper{ }
// 页面宽度
.yj-width{ max-width: 1200px; margin: 0 auto; }

/*
* z-index规范
* 页面: 0 ~ 99
* Modal: 1000 ~ 1050
* Toast: 1051 ~ 1099
*/
$zIndexHeader: 100;
$zIndexModal: 1000;
